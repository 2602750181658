<template>
  <div class="page">
    <div class="welcome">
      <img src="~assets/img/common/logo.png" alt="logo图" />
      <p class="en">Welcome</p>
      <p class="cn">欢迎登录中国中车后台管理系统</p>
    </div>
    <div class="login">
      <p class="title">欢迎登录</p>
      <form @submit.prevent="confirmData">
        <div class="phone">
          <span class="icon"></span>
          <input
            type="text"
            name="phone"
            v-model="loginInfo.phone"
            placeholder="请输入手机号"
          />
          <div class="msg">{{ msg.phone }}</div>
        </div>
        <div class="password">
          <span class="icon"></span>
          <input
            type="password"
            name="password"
            v-model="loginInfo.repassword"
            placeholder="请输入密码"
          />
          <div class="msg">{{ msg.password }}</div>
        </div>
        <div class="submit">
          <button type="submit" @click="login">登录</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoginJS from 'network/login/login.js'

export default {
  name: 'Login',
  data: () => {
    return {
      loginInfo: {
        phone: '',
        password: '',
        repassword: ''
      },
      msg: {
        phone: '',
        password: ''
      }
    }
  },
  methods: {
    /**
     * 监听事件
     */
    login() {
      if (this.confirmData()) {
        this.userLogin()
      }
    },
    /**
     * 网络连接
     */
    // 用户登录
    userLogin() {
      this.loginInfo.password = this.$md5(this.loginInfo.repassword)
      this.loginInfo.repassword = ''
      LoginJS.userLogin(this.loginInfo).then((res) => {
        if (res.code == 0) {
          // 将登陆信息持久化存储
          const loginInfo = {
            session_id: res.session_id,
            userid: res.userid,
            gtype_name: res.gtype_name,
            gtype: res.gtype,
            name: res.name
          }
          sessionStorage.setItem('loginInfo', JSON.stringify(loginInfo))
          this.$store.dispatch('setLoginAsync', loginInfo)
          this.$router.push('/userList')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 其它方法
     */

    // 判断验证
    confirmData() {
      // const obj = this.loginInfo
      // const phoneRule = /^1[34578]\d{9}$/
      // const pwdRule = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,10}$/
      // if (obj.phone.length == '' || !phoneRule.test(obj.phone)) {
      //   this.msg.phone = '请核对手机号'
      //   return false
      // } else {
      //   this.msg.phone = ''
      // }
      // if (!pwdRule.test(obj.password)) {
      //   this.msg.password = '请核对密码'
      //   return false
      // } else {
      //   this.msg.password = ''
      // }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-height: 100vh;
}
.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1068px;
  min-height: 100vh;
  background: url('~assets/img/login/bg.png');
  background-size: contain;
  img {
    width: 157px;
    height: 69px;
  }
  .en {
    margin: 46px 0;
    width: auto;
    height: 72px;
    font-size: 72px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
  .cn {
    width: auto;
    height: 36px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 852px;
  min-height: 100vh;
  background: #fff;
  .title {
    width: 554px;
    height: 36px;
    text-align: left;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
  form {
    margin-top: 61px;
    width: 554px;
    height: auto;
    > div {
      position: relative;
      width: 100%;
      height: auto;
      .msg {
        height: 54px;
        line-height: 54px;
        font-size: 19px;
        font-family: Source Han Sans CN;
        color: red;
      }
      input {
        padding-left: 69px;
        width: 436px;
        height: 54px;
        font-size: 19px;
        font-family: Source Han Sans CN;
        color: #333333;
        background: #f3f3f3;
        &::placeholder {
          letter-spacing: 2px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          color: #c2c2c2;
        }
      }
      button {
        width: 436px;
        height: 54px;
        letter-spacing: 4px;
        font-size: 23px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(7, 20, 19, 0.35);
        border-color: #019ee5;
        background: #019ee5;
      }
    }
    .phone .icon {
      position: absolute;
      left: 21px;
      top: 14px;
      width: 19px;
      height: 29px;
      background: url('~assets/img/login/icon--phone.png');
      background-size: contain;
    }
    .password .icon {
      position: absolute;
      left: 21px;
      top: 14px;
      width: 23px;
      height: 27px;
      background: url('~assets/img/login/icon-pwd.png');
      background-size: contain;
    }
  }
}
</style>
