import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  loginInfo: {
    session_id: '',
    userid: ''
  }
};
const mutations = {
  setLogin (state, playlod) {
    state.loginInfo = playlod;
  }
}

const actions = {
  setLoginAsync (context, playlod) {
    context.commit('setLogin', playlod);
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
