import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'; // 导入模块
import md5 from 'js-md5';
import './utils/flexible'
import 'element-ui/lib/theme-chalk/index.css';
import { Form, FormItem, Table, TableColumn, MessageBox, Dialog, Popover, Icon, Message, Progress, Tooltip, Select, Option, Input, Button, Pagination, Checkbox, Cascader } from 'element-ui';
moment.locale('zh-cn'); // 设置语言 
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$md5 = md5;

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dialog);
Vue.use(Popover);
Vue.use(Icon);
Vue.use(Progress);
Vue.use(Tooltip);
Vue.use(Select);
Vue.use(Option)
Vue.use(Input)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(Cascader)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
