import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from 'views/login/Login.vue'
const UserList = () => import('views/user/UserList.vue')
const UserCreate = () => import('views/user/UserCreate.vue')
const UserModifyBasic = () => import('views/user/UserModifyBasic.vue')
const UserModifyPassword = () => import('views/user/UserModifyPassword.vue')
const UserModifyRelation = () => import('views/user/UserModifyRelation.vue')
const CompanyList = () => import('views/company/CompanyList.vue')
const CompanyCreate = () => import('views/company/CompanyCreate.vue')
const CompanyModifyBasic = () => import('views/company/CompanyModifyBasic.vue')
const OilfieldList = () => import('views/oilfield/OilfieldList.vue')
const OilfieldCreate = () => import('views/oilfield/OilfieldCreate.vue')
const OilfieldModifyBasic = () => import('views/oilfield/OilfieldModifyBasic.vue')
const MachineList = () => import('views/machine/MachineList.vue')
const MachineCreate = () => import('views/machine/MachineCreate.vue')
const MachineModifyBasic = () => import('views/machine/MachineModifyBasic.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/userList',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/userCreate',
    name: 'UserCreate',
    component: UserCreate
  },
  {
    path: '/userModifyBasic',
    name: 'UserModifyBasic',
    component: UserModifyBasic
  },
  {
    path: '/userModifyPassword',
    name: 'UserModifyPassword',
    component: UserModifyPassword
  },
  {
    path: '/userModifyRelation',
    name: 'UserModifyRelation',
    component: UserModifyRelation
  },
  {
    path: '/companyList',
    name: 'CompanyList',
    component: CompanyList
  },
  {
    path: '/companyCreate',
    name: 'CompanyCreate',
    component: CompanyCreate
  },
  {
    path: '/companyModifyBasic',
    name: 'CompanyModifyBasic',
    component: CompanyModifyBasic
  },
  {
    path: '/oilfieldList',
    name: 'OilfieldList',
    component: OilfieldList
  },
  {
    path: '/oilfieldCreate',
    name: 'OilfieldCreate',
    component: OilfieldCreate
  },
  {
    path: '/oilfieldModifyBasic',
    name: 'OilfieldModifyBasic',
    component: OilfieldModifyBasic
  },
  {
    path: '/machineList',
    name: 'MachineList',
    component: MachineList
  },
  {
    path: '/machineCreate',
    name: 'MachineCreate',
    component: MachineCreate
  },
  {
    path: '/machineModifyBasic',
    name: 'MachineModifyBasic',
    component: MachineModifyBasic
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
