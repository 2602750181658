import { request } from '../request';

// 用户登陆
const userLogin = function (params) {
  return request({
    url: 'api/user/login',
    method: 'post',
    data: params
  })
};

export default {
  userLogin
}
